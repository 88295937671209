var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "no-scope"
  }, [_c('br'), _vm.$route.name === 'Overview dashbord' ? _c('News') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }