<template lang="html">
  <div class="no-scope">
    <br>
    <News v-if="$route.name === 'Overview dashbord' " />
  </div>
</template>

<script>
import News from '@/views/Notifications/News'
export default {
  components: {News},
  name: 'NoScope'
}
</script>

<style lang="scss">
    .no-scope {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>
