<template>
  <div class="vendors-overview">
    <div v-if="scopeQs">
      <Row
        type="flex"
        :gutter="24"
      >
        <Col
          :xs="24"
          :sm="24"
          :md="8"
          class="small-widget-col"
        >
          <ApiWidgetFactory
            namespace="vendor"
            :description="{title: $t('global.vendor.active_no_consent'), desc: $t('global.vendor.active_no_consent_desc')}"
            :params="{onlyDetected: true, limit: 1, cmpScenarioOutcomes: ['NO_ACTION']}"
            type="ONE_VALUE"
          />
        </Col>
        
        <Col
          :xs="24"
          :sm="24"
          :md="8"
          class="small-widget-col"
        >
          <ApiWidgetFactory
            namespace="vendor"
            :description="{title: $t('global.vendor.active_explicit_consent'), desc: $t('global.vendor.active_explicit_consent_desc')}"
            :params="{onlyDetected: true, limit: 1, cmpScenarioOutcomes: ['ACCEPT']}"
            type="ONE_VALUE"
          />
        </Col>
        <Col
          :xs="24"
          :sm="24"
          :md="8"
          class="small-widget-col"
        >
          <ApiWidgetFactory
            namespace="vendor"
            :description="{title: $t('global.vendor.active_despite_refusal'), desc: $t('global.vendor.active_despite_refusal_desc')}"
            :params="{onlyDetected: true, limit: 1, cmpScenarioOutcomes: ['REJECT']}"
            type="ONE_VALUE"
          />
        </Col>
      </Row>
      <Row
        type="flex"
        :gutter="24"
      >
        <Col
          :xs="24"
          :sm="24"
          :md="24"
          class="small-widget-col"
        >
          <Tabs
            type="card"
            :animated="false"
            @on-click="handleTabChange"
          >
            <TabPane
              v-for="tab in Object.values(tabs)"
              :key="tab.name"
              class="tab-panel"
              :label="tab.label"
              :name="tab.name"
            >
              <WidgetTile
                :api="tab.widget"
                :is-actionable="true"
                :key="activeTabName"
                :style="{position: 'relative', top: 0, left: 0}"
              />
            </TabPane>
          </Tabs>
        </Col>
        <Col
          :xs="24"
          :sm="24"
          :md="24"
          class="small-widget-col"
        >
          <Tabs
            type="card"
            :animated="false"
            @on-click="handleTabChangeTable"
            data-tests="vendors-cmp-scenarios-table-tabs"
          >
            <TabPane
              v-for="tab in Object.values(tabsTable)"
              :key="tab.name"
              class="tab-panel"
              :label="`${counts[tab.name]} ${tab.label}`"
              :name="tab.name"
            >
              <p> <strong>{{ tab.desc }} </strong></p>
              <FilterTable
                namespace="vendor"
                :key="tableKey"
                :trigger="trigger"
                :export-csv="vendorExportCsv"
                :fetch-items="vendorsGetVendors"
                :fetch-items-params="params(tab.name)"
                :columns="generateCols"
                :search-filters="['isActiveInTcf', 'hasDeclaredLegitimateInterests', 'isInAdstxt', 'vendorSurveillanceStatus', 'search','show-category-exemption', 'isExempted']"
                @on-items-fetched="res => onVendorsFetched(res, tab.name)"
                data-tests="vendors-cmp-scenarios-table"
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <SetupVendorModal
        ref="vendorRulesModal"
        @on-form-submit-success="handleModalFormSubmit"
      />
    </div>
    <NoScope v-else />
  </div>
</template>

<script>
import WidgetTile from '@/components/ui/WidgetTile'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import NoScope from '@/components/ui/NoScope'
import {mapGetters, mapActions} from 'vuex'
import tableColumn from '@/mixins/tableColumn'
import SetupVendorModal from '@/views/Vendors/Modals/SetupVendorModal'
import FilterTable from '@/components/ui/FilterTable'

export default {
  name: 'Overview',
  mixins: [tableColumn],
  components: {
    WidgetTile,
    SetupVendorModal,
    FilterTable,
    ApiWidgetFactory,
    NoScope
  },
  watch: {
    '$route': {
      handler: function(search) {
        const hash = this.$route.hash
        if (hash === '#despite') this.currentTab = 'noConsent'
        if (hash === '#nochoice') this.currentTab = 'beforeConsent'
        if (hash === '#accept') this.currentTab = 'afterConsent'
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      activeTabName: 'allVendors',
      trigger: false,
      // Table method
      activeTabNameTable: 'allVendors',
      // Table method
      counts: {
        allVendors: '...',
        beforeConsent: '...',
        afterConsent: '...',
        noConsent: '...'
      },
      // Table method
      tableKey: true,
      // Table method
      tabsTable: {
        allVendors: {
          name: 'allVendors',
          desc: `${this.$t('global.scenarios.allVendors')}`,
          params: {onlyDetected: true},
          label: ` ${this.$t('global.scenarios.allVendors')}`
        },
        beforeConsent: {
          name: 'beforeConsent',
          desc: `${this.$t('global.vendor.active_no_consent_desc')}`,
          params: {cmpScenarioOutcomes: ['NO_ACTION']},
          label: ` ${this.$t('global.scenarios.without_choice')}`
        },
        afterConsent: {
          name: 'afterConsent',
          desc: `${this.$t('global.vendor.active_explicit_consent_desc')}`,
          params: {cmpScenarioOutcomes: ['ACCEPT']},
          label: ` ${this.$t('global.scenarios.with_choice')}`
        },
        noConsent: {
          name: 'noConsent',
          desc: `${this.$t('global.vendor.active_despite_refusal_desc')}`,
          params: {cmpScenarioOutcomes: ['REJECT']},
          label: ` ${this.$t('global.scenarios.refusal_choice')}`
        }
      },
      tabs: {
        allVendors: {
          name: 'all',
          widget: 'wvendors/vendors34',
          label: this.$t('global.scenarios.allVendors')
        },
        beforeConsent: {
          name: 'beforeConsent',
          widget: 'wvendors/vendors34?cmpScenarioOutcome=NO_ACTION',
          label: this.$t('global.scenarios.without_choice')
        },
        afterConsent: {
          name: 'afterConsent',
          widget: 'wvendors/vendors34?cmpScenarioOutcome=ACCEPT',
          label: this.$t('global.scenarios.with_choice')
        },
        noConsent: {
          name: 'noConsent',
          widget: 'wvendors/vendors34?cmpScenarioOutcome=REJECT',
          label: this.$t('global.scenarios.refusal_choice')
        }
      }
    }
  },
  methods: {
    ...mapActions({
      vendorsGetVendors: 'vendor/getVendors',
      vendorExportCsv: 'vendor/exportCsv'
    }),
    doesHelperColumn () {
      const { showCategoryHelper } = this
      return showCategoryHelper 
        ? {
          key: 'Category.name',
          title: this.$t('global.table.category'),
          width: 250,
          sortable: 'custom',
          sortKey: 'Categories.name',
          render: (h, params) => h('Poptip', {
            props: {
              content:params.row.category.description,
              placement:"right",
              'word-wrap': true,
              style:{wordWrap: 'break-word'},
              width: 300,
              trigger:'hover'
            }
          }, [
            h('Badge', {
              slot:"title",
              props: {
                color: this.complianceColor[this.vendorVigilanceToLetter(params.row.category.vigilance_id)],
                text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.category.vigilance_id) + (params.row.category.is_exempted ? '  - Exempted' : '')
              }
            },),
            // <i class="fas fa-check-double"></i>
            params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category.name
                
              ])
              : h('span', {}, params.row.category.name)
          ])
        }
        : { 
          title: this.$t('global.table.category'),
          sortKey: 'Categories.name',
          sortable: 'custom',
          render: (h, params) => {
            return params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category.name
                
              ])
              : h('span', {}, params.row.category.name)
          }
        }
     
    },
    // Table method
    params (tabName) {
      return {
        sort: 'Vendors.name',
        direction: 'asc',
        properties: [this.scopeSelectedProperty.id],
        period: this.scopeSelectedDaterange,
        onlyDetected: true,
        TCF_legacy: 'ALL',
        privacy_policy_status: 'ALL',
        tms_status: 'ALL',
        ...this.tabsTable[tabName].params
      }
    },
    // Table method
    handleTabChangeTable (tabName) {
      this.activeTabNameTable = tabName
    },
    // Table method
    handleModalFormSubmit () {
      this.trigger = !this.trigger
    },
    // Table method
    getVendorStatus (vendor) {
      if (vendor.property_vendordomain_rules.length === 0) {
        return this.$t('global.filters.under_qualification')
      }
      if (vendor.property_vendordomain_rules.find(elm => elm.status === '1')) {
        return this.$t('global.filters.watchlist')
      }
      return this.$t('global.filters.whitelist')
    },
    // Table method
    vendorVigilance (vendor) {
      const riskColor = {
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      }
      const vigilance = this.vendorVigilanceToLetter(vendor.vigilance_rating * 5)

      return {
        vigilance: vigilance,
        color: riskColor[vigilance]
      }
    },
    // Table method
    toggleEditModal (vendor) {
      this.$refs['vendorRulesModal'].openModal(vendor)
    },
    // Table method
    onVendorsFetched (res, tabName) {
      this.counts[tabName] = res.pagination.count
    },
    handleTabChange (tabName) {
      this.activeTabName = tabName
    }
  },
  computed: {
    ...mapGetters({
      scopeQs: 'scope/qs',
      scopeSelectedDaterange: 'scope/selectedDaterange',
      scopeSelectedProperty: 'scope/selectedProperty',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      showCategoryHelper: 'vendor/showCategoryHelper',
      complianceColor: 'vendor/riskColor'
    }),
    generateCols () {
      return [
        this.createColumn(this.$t('global.table.name'), 'name', 'Vendors.name', 'vendorLink'),
        {
          title: this.$t('global.filters.is_exempted'),
          sortable: 'custom',
          sortKey: 'Vendors.is_exempted',
          render: (h, params) => {
            const render = h('Tag', {
              props: {
                color: 'blue'
              }
            }, 'Exempted')
            return params.row.is_exempted === 1 ? render : ''
          }
        },
        this.doesHelperColumn(),
        
        this.createColumn(this.$t('global.table.country'), 'country.displayName', 'Countries.name', 'underQualification'),
        this.createColumn(this.$t('global.table.tcf'), 'iabv2Id', 'Vendors.iabv2Id', null, 100),
        this.createColumn(this.$t('global.table.adstxt'), 'adstxt', null, row => ({
          content: row.adstxtvendors.length > 0 ? this.$t('global.filters.in') : this.$t('global.filters.out')
        })),
        this.createColumn(this.$t('global.table.status'), null, null, row => {
          const status = this.getVendorStatus(row)

          return {
            tag: 'div',
            classes: {
              whitelist: status === this.$t('global.filters.whitelist'),
              watchlist: status === this.$t('global.filters.watchlist'),
              qualification: status === this.$t('global.filters.under_qualification')},
            style: {position: 'relative', paddingLeft: '20px'},
            content: status
          }
        }, 200),
        this.createColumn(this.$t('global.table.vigilance'), null, 'Vendors.vigilance_rating', row => {
          const {vigilance, color} = this.vendorVigilance(row)

          return {
            tag: 'Tag',
            props: {color, type: 'dot'},
            content: vigilance
          }
        }),
        this.createColumn(this.$t('global.table.action'), null, null, row => ({
          tag: 'Button',
          props: {type: 'primary'},
          events: {click: () => this.toggleEditModal(row)},
          classes: 'btn btn--elevated',
          content: () => ({tag: 'strong', content: this.$t('global.table.qualify')})
        }))
      ]
    }
  }
}
</script>
