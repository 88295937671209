<template>
  <Modal
    :title="$t('global.vendor.setup_rules')"
    class="vendor-manage"
    width="760px"
    v-model="isModalOpen"
    @on-cancel="handleModalCancel()"
  >
    <div v-if="hasVendorFound">
      <Card
        dis-hover
        :bordered="false"
      >
        <div
          class="card-header"
          slot="title"
        >
          <span><strong>
            {{ vendor.name }}</strong>
          </span>
          <span>
            <Button
              type="success"
              ghost
              @click="$router.push('/vendors/'+ vendor.uuid)"
            >{{ $t('global.graph.view_vendor') }}</Button>
          </span>
          <div
            class="boxes-container"
            v-if="isModalOpen"
          >
            <div :style="{border: `1px solid ${computed_risk_color}`, color: computed_risk_color}">
              {{ $t('global.table.vigilance') }}
              {{
                vendorVigilanceToLetter(this.vendor.vigilance_rating * 5)
              }}
            </div>

            <div :class="{ valide: vendor.iabv2Id, 'not-valide': !vendor.iabv2Id }">
              <i
                v-if="vendor.iabv2Id"
                class="fas fa-check-circle"
                style="padding:0 5px;"
              />
              <i
                v-else
                class="fas fa-times-circle cmp-icon"
                style="padding:0 5px;"
              />
              {{ $t('global.vendor.tcf2') }}
            </div>

            <div
              :class="{ valide: vendor.adstxtvendors.length > 0, 'not-valide': vendor.adstxtvendors.length === 0}"
              type="success"
            >
              <i
                v-if="vendor.adstxtvendors.length > 0 "
                class="fas fa-check-circle"
                style="padding:0 5px;"
              />
              <i
                v-else
                class="fas fa-times-circle cmp-icon"
                style="padding:0 5px;"
              />
              {{ $t('global.table.adstxt') }}
            </div>
            <!-- <div :class="{ valide: vendor.isCMPDeclared, 'not-valide': !vendor.isCMPDeclared }">
                      <i v-if="vendor.isCMPDeclared" class="fas fa-check-circle" style="padding:0 5px;"></i>
                      <i v-else  class="fas fa-times-circle cmp-icon" style="padding:0 5px;"></i>
                      CMP
                    </div> -->
          </div>
        </div>
        <div>
          <br>
          <Form
            ref="updateSetupVendorForm"
            :model="vendorForm"
            label-position="top"
            inline
          >
            <FormItem
              prop="inTagManagers"
              label="Tag Manager System"
            >
              <i-switch v-model="vendorForm.inTagManagers" />
            </FormItem>
            <FormItem
              prop="inPrivacyPolicy"
              label="In Privacy Policy"
            >
              <i-switch v-model="vendorForm.inPrivacyPolicy" />
            </FormItem>
          </Form>
        </div>
      </Card>

      <Card
        dis-hover
        :bordered="false"
        shadow
      >
        <div
          class="legend-title"
          slot="title"
        >
          <p style="font-size:22px;line-height:34px;margin-bottom:12px">
            {{ $t('global.vendor.qualify_vendor_domains') }}
            <strong>{{ vendor.name }}</strong>
          </p>
          <div>
            <p>
              <strong>{{ $t('global.filters.whitelist') }} : </strong>
              {{ $t('global.vendor.vendor_adstxt_cmp_white') }}
            </p>
            <p>
              <strong>{{ $t('global.filters.watchlist') }} : </strong>
              {{ $t('global.vendor.vendor_adstxt_cmp_watch') }}
            </p>
            <p>
              <strong>Nb : </strong>
              {{ $t('global.vendor.vendor_if_watchlist_domain') }}
            </p>
          </div>
        </div>

        <div>
          <!-- <rules-legend></rules-legend> -->

          <!-- <Divider></Divider> -->
          <div class="table-head">
            <div>{{ $t('global.filters.whitelist') }}</div>
            <div>{{ $t('global.filters.watchlist') }}</div>
            <div>Domaines</div>
          </div>
          <ul class="table-content">
            <li
              v-for="(item, index) in tree"
              :key="'vendordomainslist'+item.id"
              @mouseenter="handleVendordomainslistStatusMouseenter(item)"
              @mouseleave="handleVendordomainslistStatusMouseleave(item)"
            >
              <span :class="{'span-container' : item.hasVendor || index !== 0 && item.level === 1, 'span-nochoice' : true}">
                <input
                  v-if="item.hasVendor"
                  type="radio"
                  value="2"
                  @click="handleVendordomainslistStatusClick(item, '2')"
                  v-model="item.property_vendordomain_rules[0].status"
                >
              </span>
              <span :class="{'span-container' : item.hasVendor || index !== 0 && item.level === 1, 'span-nochoice' : true}">
                <input
                  v-if="item.hasVendor"
                  type="radio"
                  value="1"
                  @click="handleVendordomainslistStatusClick(item, '1')"
                  v-model="item.property_vendordomain_rules[0].status"
                >
              </span>
              <span
                class="span-container"
                :style="{'padding-left': (item.level) +'rem'}"
              >
                <b v-if="item.highlight">
                  {{ item.title }}
                </b>
                <span v-else>
                  {{ item.title }}
                </span>
                <span v-if="item.property_vendordomain_rules[0].manualOverride">*</span>
                <i v-if="!item.hasVendor">
                  ({{ item.vendor && item.vendor.name }})
                </i>
              </span>
            </li>
          </ul>
        </div>
      </Card>
    </div>
    <div slot="footer">
      <Button
        type="primary"
        class="btn btn--elevated"
        @click="handleSubmitVendorForm('updateSetupVendorForm')"
        :loading="isFetching"
        long
      >
        {{ $t('global.filters.update') }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: 'SetupVendorModal',
  data () {
    return {
      riskColor: {
        grey: '#616a6894',
        A: '#81C327',
        B: '#6BAAD8',
        C: '#FFBD41',
        D: '#FF7850',
        E: '#D55D78'
      },
      vendor: {},
      treeData: [],
      tree: [],
      isModalOpen: false,
      isFetching: true,
      vendorForm: {
        inTagManagers: false,
        inPrivacyPolicy: false,
        inWhitelist: false
      },
      selectedRows: []
    }
  },
  computed: {
    ...mapGetters({
      'scopeSelectedProperty': 'scope/selectedProperty',
      'vendorVigilanceToLetter': 'vendor/vendorVigilanceToLetter'
    }),
    computed_risk_color () {
      const { riskColor, vendorVigilanceToLetter, vendor } = this
      const color = riskColor[vendorVigilanceToLetter(vendor.vigilance_rating * 5)]
      return color
    },
    hasVendorFound () {
      return this.vendor && !!this.vendor.id
    }
  },
  methods: {
    handleVendordomainslistStatusMouseenter (hoverItem) {
      this.tree.forEach(e => {
        this.$set(e, 'highlight', (e.reverse.startsWith(hoverItem.reverse + '.')) && e.hasVendor &&
              !e.property_vendordomain_rules[0].manualOverride)
      })
      this.$set(hoverItem, 'highlight', true)
    },
    handleVendordomainslistStatusMouseleave (hoverItem) {
      this.tree.forEach(e => {
        this.$set(e, 'highlight', false)
      })
    },
    handleVendordomainslistStatusClick (clickedItem, newStatus) {
      const cascadeStatus = (item, newStatus) => {
        // do not handle unnecessary items
        if (!item.hasVendor) {
          return
        }
        console.log('requesting status change to item : ', item.domain_pattern, ' / status :', newStatus)
        const parent = this.tree.find(
          e => (e.level === (item.level - 1) && item.reverse.startsWith(e.reverse + '.')))
        parent && console.log('item parent :', parent && parent.domain_pattern, parent)
        const children = this.tree.filter(
          e => (e.level === (item.level + 1) && e.reverse.startsWith(item.reverse + '.')))
        children.length && console.log('item children :', children)
        // store previous status before any change
        item.property_vendordomain_rules[0].prevStatus = item.property_vendordomain_rules[0].status

        if (!parent || !parent.hasVendor) {
          // top level or no matching vendor parent
          console.log(item.property_vendordomain_rules[0].status)
          console.log(newStatus)
          item.property_vendordomain_rules[0].manualOverride = (newStatus !== null)
          item.property_vendordomain_rules[0].status = newStatus
        } else if (item === clickedItem) {
          item.property_vendordomain_rules[0].manualOverride = true
          item.property_vendordomain_rules[0].status = newStatus
        } else {
          if (newStatus === item.property_vendordomain_rules[0].status ||
                parent.property_vendordomain_rules[0].prevStatus ===
                item.property_vendordomain_rules[0].status) {
            item.property_vendordomain_rules[0].manualOverride = false
            item.property_vendordomain_rules[0].status = newStatus
          } else {
            console.log(item.domain_pattern, 'newStatus', newStatus)
            console.log(item.domain_pattern, 'item.property_vendordomain_rules[0].status',
              item.property_vendordomain_rules[0].status)
            console.log(item.domain_pattern, 'parent.property_vendordomain_rules[0].prevStatus',
              parent.property_vendordomain_rules[0].prevStatus)
            console.log(item.domain_pattern, 'item.property_vendordomain_rules[0].status',
              item.property_vendordomain_rules[0].status)
            console.log(item.domain_pattern, 'manual override to', newStatus, item)
            item.property_vendordomain_rules[0].manualOverride = true
            newStatus = item.property_vendordomain_rules[0].status
            // item.property_vendordomain_rules[0].status = newStatus
          }
        }
        children.forEach(c => {
          cascadeStatus(c, newStatus)
        })
      }
      // if current status is same as new then it's an 'unset'
      console.log(clickedItem.property_vendordomain_rules[0].status)
      console.log(newStatus)
      if (clickedItem.property_vendordomain_rules[0].status === newStatus) {
        newStatus = null
      }

      cascadeStatus(clickedItem, newStatus)
    },
    /**
       * Get current tree rules state.
       * @returns {{vendordomain_id: *, property_id: *, status: null}[]}
       */
    snapshotTreeState () {
      return this.tree.map(item => {
        let status = null

        if (item.property_vendordomain_rules[0].status) {
          status = item.property_vendordomain_rules[0].status
        }

        return {
          property_id: this.scopeSelectedProperty.id,
          vendordomain_id: item.id,
          status: status ? parseInt(status) : status
        }
      })
    },
    handleModalCancel () {
      this.closeModal()
    },
    closeModal () {
      this.isModalOpen = false
    },
    async openModal (vendor) {
      this.vendor = vendor
      const run = (items, iter = 1) => {
        console.log('run data')

        // let vendorDomainsTree = []

        // building tree
        // starting from level lvl
        for (let lvl = 10; lvl > 0; lvl--) {
          for (const item of items) {
            if (item.level === lvl) {
              item.hasVendor = item.hasVendor || (item.vendor_id === this.vendor.id)

              // set default rules
              if (item.property_vendordomain_rules.length) {
                item.property_vendordomain_rules[0] = {
                  ...item.property_vendordomain_rules[0],
                  // manualOverride: true, // test
                  manualOverride: (item.property_vendordomain_rules[0].status !== null),
                  status: (item.property_vendordomain_rules[0].status !== null)
                    ? ('' + item.property_vendordomain_rules[0].status)
                    : null

                }
              } else {
                item.property_vendordomain_rules.push({
                  manualOverride: false,
                  status: null

                })
              }
              let parent = items.find(p => {
                return ((p.level === (item.level - 1)) && p.sld === item.sld &&
                      item.reverse.startsWith(p.reverse + '.'))
              })
              parent || (item.title = item.domain_pattern.replace('\\', '').replace('$', ''))
              parent && (item.title = item.reverse.replace(parent.reverse + '.', ''))
            }
          }
        }
        console.log('ran', items)
        items.forEach(e => {
          console.log(e.domain_pattern, e.property_vendordomain_rules[0])
        })
        this.tree = items
      }

      this.isFetching = true

      try {
        const {data: response} = await this.$axios.get('api/vendordomains', {
          cache: {
            exclude: {query: true}
          },
          params: {
            vendor_id: this.vendor.id, // 104,
            property_id: this.scopeSelectedProperty.id // 37582
          }
        })

        if (response.success) {
          run(response.data)
        } else {
          console.error(response.message)
        }
      } catch (e) {
        console.error(e.message)
      } finally {
        this.isFetching = false
      }

      this.isModalOpen = true

      // Force update to refresh the modal (tmp fix...)
      this.$forceUpdate()

      this.vendorForm = {
        inTagManagers: this.vendor.inTagManagers,
        inPrivacyPolicy: this.vendor.inPrivacyPolicy,
        inWhitelist: this.vendor.inWhitelist
      }
    },
    // Update selection list
    handleRowSelection (selectedRows) {
      this.selectedRows = selectedRows
    },
    handleSubmitVendorForm (name) {
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          if (!this.vendor) {
            throw new Error('No vendors found during form submission.')
          }

          const payload = {
            ...this.vendorForm,
            propertyId: this.scopeSelectedProperty.id,
            vendors: [this.vendor.id]
          }

          this.isFetching = true

          try {
            const rules = this.snapshotTreeState()

            await this.$axios.post('/api/property_vendordomain_rules', rules)

            const {data: response} = await this.$axios.post('properties/updateSetupVendor', payload)

            if (response.success) {
              this.$Message.success(response.message)
              this.$emit('on-form-submit-success', payload)
              this.closeModal()
            } else {
              this.$Message.error(response.message)
            }
          } catch (err) {
            this.$Message.error('Server error, please try again.')
            console.error('handleSubmit', err)
          } finally {
            this.isFetching = false
          }
        } else {
          this.$Message.error('The form is invalid, please verify it')
        }
      })
    }
  }
}
</script>
<style lang="scss">
    .legend-title {
      font-weight: 400!important;
      p {
        font-size:16px!important;
      }

    }
    .vendor-manage .legend-title p {
          font-weight: 200!important;
    }
  .vendor-manage{
    .card-header {
      display:flex;justify-content: space-between;align-items:center;flex-wrap:wrap;
    }
    .legend-title {
      overflow:visible;
      white-space:inherit;
      text-overflow:inherit;
      padding:10px 0;
      p {
        text-transform: initial;
        height: inherit;
        font-weight: normal;
        padding: 10px 0;
        overflow: visible;
        text-overflow: inherit;
        white-space: inherit;
      }
    }
    .table-head {
      display: flex;
      div {
        padding: 8px;
        width: 20%;
        border: 1px solid #ebebeb;
        &:nth-child(2) {
          border-left: none;
          border-right: none;
        }
        &:last-child {
          width: 60%
        }
      }
    }
    .table-content {
      border: 0px 1px 1px 1px solid #ebebeb;
      li {
        display: flex;
        width:100%;
        .span-nochoice {
          padding: 8px;
            width: 20%;
            border: 1px solid #ebebeb;
            border-top: none;
            border-bottom: none;
            &:nth-child(2) {
            border-left: none;
            border-right: none;
          }
          &:last-child {
            border-bottom: none;
            width: 60%
          }
        }
        .span-container {
            input {
              transform: scale(1.5);
            }
            text-align: center;
            padding: 8px;
            width: 20%;
            border: 1px solid #ebebeb;
            border-bottom: none;
            &:nth-child(2) {
            border-left: none;
            border-right: none;
          }
          &:last-child {
            border-bottom: none;
            text-align: left;
            width: 60%
          }
        }
      }
    }
    .boxes-container {
      margin-top: 34px;
      width:100%;
      margin-top:24px;
      display:flex;
      justify-content:space-between;
      div{
      width: 25%;
      border-radius: 8px;
      height: 50px;
      // 50px - 5padding - 2border
      line-height: 43px;
      padding: 5px;
      text-align: center;
      background: #f4f4f4;
      }
    }
    .valide {
      color: #6eba3e;
      border: 1px solid #6eba3e;
    }
    .not-valide {
      color: #d7215f;
      border: 1px solid #d7215f;
    }
    .ivu-btn-success {
      font-size: 16px;
      color: #1ca08d;
      border-color: #1ca08d;
    }
  }
</style>
