var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notifications-container"
  }, [_vm.messages.length ? _c('Timeline', _vm._l(_vm.chunckDataByDate(_vm.messages), function (messagesArr, k) {
    return _c('div', {
      key: k
    }, [_c('Card', [_c('span', {
      staticClass: "time",
      attrs: {
        "slot": "title"
      },
      slot: "title"
    }, [_vm._v(" " + _vm._s(messagesArr.date) + " ")]), _vm._l(messagesArr.arr, function (message, key) {
      return _c('div', {
        key: key
      }, [_c('h1', [_vm._v(" " + _vm._s(message.title) + " " + _vm._s(message.type === 'log' ? " ".concat(_vm.date(message.created)) : '') + " ")]), _c('p', {
        staticClass: "content",
        domProps: {
          "innerHTML": _vm._s(message.description)
        }
      }), _c('br'), message.img ? _c('figure', [_c('img', {
        attrs: {
          "width": "100%",
          "src": message.img
        }
      })]) : _vm._e()]);
    })], 2), _c('br')], 1);
  }), 0) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }