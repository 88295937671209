var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "vendor-manage",
    attrs: {
      "title": _vm.$t('global.vendor.setup_rules'),
      "width": "760px"
    },
    on: {
      "on-cancel": function onCancel($event) {
        return _vm.handleModalCancel();
      }
    },
    model: {
      value: _vm.isModalOpen,
      callback: function callback($$v) {
        _vm.isModalOpen = $$v;
      },
      expression: "isModalOpen"
    }
  }, [_vm.hasVendorFound ? _c('div', [_c('Card', {
    attrs: {
      "dis-hover": "",
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('span', [_c('strong', [_vm._v(" " + _vm._s(_vm.vendor.name))])]), _c('span', [_c('Button', {
    attrs: {
      "type": "success",
      "ghost": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/vendors/' + _vm.vendor.uuid);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('global.graph.view_vendor')))])], 1), _vm.isModalOpen ? _c('div', {
    staticClass: "boxes-container"
  }, [_c('div', {
    style: {
      border: "1px solid ".concat(_vm.computed_risk_color),
      color: _vm.computed_risk_color
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.vigilance')) + " " + _vm._s(_vm.vendorVigilanceToLetter(this.vendor.vigilance_rating * 5)) + " ")]), _c('div', {
    class: {
      valide: _vm.vendor.iabv2Id,
      'not-valide': !_vm.vendor.iabv2Id
    }
  }, [_vm.vendor.iabv2Id ? _c('i', {
    staticClass: "fas fa-check-circle",
    staticStyle: {
      "padding": "0 5px"
    }
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon",
    staticStyle: {
      "padding": "0 5px"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('global.vendor.tcf2')) + " ")]), _c('div', {
    class: {
      valide: _vm.vendor.adstxtvendors.length > 0,
      'not-valide': _vm.vendor.adstxtvendors.length === 0
    },
    attrs: {
      "type": "success"
    }
  }, [_vm.vendor.adstxtvendors.length > 0 ? _c('i', {
    staticClass: "fas fa-check-circle",
    staticStyle: {
      "padding": "0 5px"
    }
  }) : _c('i', {
    staticClass: "fas fa-times-circle cmp-icon",
    staticStyle: {
      "padding": "0 5px"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('global.table.adstxt')) + " ")])]) : _vm._e()]), _c('div', [_c('br'), _c('Form', {
    ref: "updateSetupVendorForm",
    attrs: {
      "model": _vm.vendorForm,
      "label-position": "top",
      "inline": ""
    }
  }, [_c('FormItem', {
    attrs: {
      "prop": "inTagManagers",
      "label": "Tag Manager System"
    }
  }, [_c('i-switch', {
    model: {
      value: _vm.vendorForm.inTagManagers,
      callback: function callback($$v) {
        _vm.$set(_vm.vendorForm, "inTagManagers", $$v);
      },
      expression: "vendorForm.inTagManagers"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "inPrivacyPolicy",
      "label": "In Privacy Policy"
    }
  }, [_c('i-switch', {
    model: {
      value: _vm.vendorForm.inPrivacyPolicy,
      callback: function callback($$v) {
        _vm.$set(_vm.vendorForm, "inPrivacyPolicy", $$v);
      },
      expression: "vendorForm.inPrivacyPolicy"
    }
  })], 1)], 1)], 1)]), _c('Card', {
    attrs: {
      "dis-hover": "",
      "bordered": false,
      "shadow": ""
    }
  }, [_c('div', {
    staticClass: "legend-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('p', {
    staticStyle: {
      "font-size": "22px",
      "line-height": "34px",
      "margin-bottom": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.qualify_vendor_domains')) + " "), _c('strong', [_vm._v(_vm._s(_vm.vendor.name))])]), _c('div', [_c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('global.filters.whitelist')) + " : ")]), _vm._v(" " + _vm._s(_vm.$t('global.vendor.vendor_adstxt_cmp_white')) + " ")]), _c('p', [_c('strong', [_vm._v(_vm._s(_vm.$t('global.filters.watchlist')) + " : ")]), _vm._v(" " + _vm._s(_vm.$t('global.vendor.vendor_adstxt_cmp_watch')) + " ")]), _c('p', [_c('strong', [_vm._v("Nb : ")]), _vm._v(" " + _vm._s(_vm.$t('global.vendor.vendor_if_watchlist_domain')) + " ")])])]), _c('div', [_c('div', {
    staticClass: "table-head"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('global.filters.whitelist')))]), _c('div', [_vm._v(_vm._s(_vm.$t('global.filters.watchlist')))]), _c('div', [_vm._v("Domaines")])]), _c('ul', {
    staticClass: "table-content"
  }, _vm._l(_vm.tree, function (item, index) {
    return _c('li', {
      key: 'vendordomainslist' + item.id,
      on: {
        "mouseenter": function mouseenter($event) {
          return _vm.handleVendordomainslistStatusMouseenter(item);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.handleVendordomainslistStatusMouseleave(item);
        }
      }
    }, [_c('span', {
      class: {
        'span-container': item.hasVendor || index !== 0 && item.level === 1,
        'span-nochoice': true
      }
    }, [item.hasVendor ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.property_vendordomain_rules[0].status,
        expression: "item.property_vendordomain_rules[0].status"
      }],
      attrs: {
        "type": "radio",
        "value": "2"
      },
      domProps: {
        "checked": _vm._q(item.property_vendordomain_rules[0].status, "2")
      },
      on: {
        "click": function click($event) {
          return _vm.handleVendordomainslistStatusClick(item, '2');
        },
        "change": function change($event) {
          return _vm.$set(item.property_vendordomain_rules[0], "status", "2");
        }
      }
    }) : _vm._e()]), _c('span', {
      class: {
        'span-container': item.hasVendor || index !== 0 && item.level === 1,
        'span-nochoice': true
      }
    }, [item.hasVendor ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.property_vendordomain_rules[0].status,
        expression: "item.property_vendordomain_rules[0].status"
      }],
      attrs: {
        "type": "radio",
        "value": "1"
      },
      domProps: {
        "checked": _vm._q(item.property_vendordomain_rules[0].status, "1")
      },
      on: {
        "click": function click($event) {
          return _vm.handleVendordomainslistStatusClick(item, '1');
        },
        "change": function change($event) {
          return _vm.$set(item.property_vendordomain_rules[0], "status", "1");
        }
      }
    }) : _vm._e()]), _c('span', {
      staticClass: "span-container",
      style: {
        'padding-left': item.level + 'rem'
      }
    }, [item.highlight ? _c('b', [_vm._v(" " + _vm._s(item.title) + " ")]) : _c('span', [_vm._v(" " + _vm._s(item.title) + " ")]), item.property_vendordomain_rules[0].manualOverride ? _c('span', [_vm._v("*")]) : _vm._e(), !item.hasVendor ? _c('i', [_vm._v(" (" + _vm._s(item.vendor && item.vendor.name) + ") ")]) : _vm._e()])]);
  }), 0)])])], 1) : _vm._e(), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "type": "primary",
      "loading": _vm.isFetching,
      "long": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleSubmitVendorForm('updateSetupVendorForm');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.filters.update')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }