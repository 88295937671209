<template>
  <div class="notifications-container">
    <Timeline v-if="messages.length">
      <div
        v-for="(messagesArr, k) of chunckDataByDate(messages)"
        :key="k"
      >
        <Card>
          <span
            slot="title"
            class="time"
          >
            {{ messagesArr.date }}
          </span>
          <div
            v-for="(message, key) of messagesArr.arr"
            :key="key"
          >
            <h1>
              {{ message.title }} {{ message.type === 'log' ? ` ${date(message.created)}` : '' }}
            </h1>
            <p
              v-html="message.description"
              class="content"
            />
            <br>
            <figure v-if="message.img">
              <img
                width="100%"
                :src="message.img"
              >
            </figure>
            <!--            <br>-->
            <!--            <hr v-if="messagesArr.arr.length !== key+1">-->
            <!--            <br>-->
          </div>
        </Card>
        <br>
      </div>
    </Timeline>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import moment from 'moment'

export default {
  name: 'News',
  data () {
    return {
      truc: false
    }
  },
  computed: {
    ...mapGetters({
      messages: 'notifications/messages',
      property: 'scope/selectedProperty',
    })
  },
  mounted () {
    // store key to display or not new messages from the bell if the user already opened it
    localStorage.setItem('fa7-last-message-opened', JSON.stringify(moment()))
  },
  methods: {
    date (dd) {
      return moment(dd).format('LL')
    },
    chunckDataByDate (array, type) {
      // create array with each different date
      let dates = [...new Set(array.map(elm => elm.created.slice(0, 10)))]
      let messages = []

      // rewrite dates in DD/MM/YYYY
      const reverseDateRepresentation = dates.map(elm => {
        let parts = elm.split('-')
        return `${parts[2]}-${parts[1]}-${parts[0]}`
      })

      // sort dates array
      const sortedDates = reverseDateRepresentation.sort(function (a, b) {
        // eslint-disable-next-line one-var
        const aa = a.split('-').reverse().join(),
          bb = b.split('-').reverse().join()
        return aa < bb ? -1 : (aa > bb ? 1 : 0)
      })

      // For each sorted date, assign an array with each notification for this date
      sortedDates.forEach(date => {
        // rewrite date in same format as API =>  YYYY/MM/DD
        const unReversedDate = [date].map(elm => {
          let parts = elm.split('-')
          return `${parts[2]}-${parts[1]}-${parts[0]}`
        })
        messages.push({
          arr: array.filter(elm => elm.created.includes(unReversedDate[0])),
          date: date
        })
      })
      // apply desc sort
      return messages.reverse()
    }
  }
}
</script>
<style>

.notifications-container {
  overflow: visible;
}
</style>
