var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vendors-overview"
  }, [_vm.scopeQs ? _c('div', [_c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('global.vendor.active_no_consent'),
        desc: _vm.$t('global.vendor.active_no_consent_desc')
      },
      "params": {
        onlyDetected: true,
        limit: 1,
        cmpScenarioOutcomes: ['NO_ACTION']
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('global.vendor.active_explicit_consent'),
        desc: _vm.$t('global.vendor.active_explicit_consent_desc')
      },
      "params": {
        onlyDetected: true,
        limit: 1,
        cmpScenarioOutcomes: ['ACCEPT']
      },
      "type": "ONE_VALUE"
    }
  })], 1), _c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 8
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "vendor",
      "description": {
        title: _vm.$t('global.vendor.active_despite_refusal'),
        desc: _vm.$t('global.vendor.active_despite_refusal_desc')
      },
      "params": {
        onlyDetected: true,
        limit: 1,
        cmpScenarioOutcomes: ['REJECT']
      },
      "type": "ONE_VALUE"
    }
  })], 1)], 1), _c('Row', {
    attrs: {
      "type": "flex",
      "gutter": 24
    }
  }, [_c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('Tabs', {
    attrs: {
      "type": "card",
      "animated": false
    },
    on: {
      "on-click": _vm.handleTabChange
    }
  }, _vm._l(Object.values(_vm.tabs), function (tab) {
    return _c('TabPane', {
      key: tab.name,
      staticClass: "tab-panel",
      attrs: {
        "label": tab.label,
        "name": tab.name
      }
    }, [_c('WidgetTile', {
      key: _vm.activeTabName,
      style: {
        position: 'relative',
        top: 0,
        left: 0
      },
      attrs: {
        "api": tab.widget,
        "is-actionable": true
      }
    })], 1);
  }), 1)], 1), _c('Col', {
    staticClass: "small-widget-col",
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 24
    }
  }, [_c('Tabs', {
    attrs: {
      "type": "card",
      "animated": false,
      "data-tests": "vendors-cmp-scenarios-table-tabs"
    },
    on: {
      "on-click": _vm.handleTabChangeTable
    }
  }, _vm._l(Object.values(_vm.tabsTable), function (tab) {
    return _c('TabPane', {
      key: tab.name,
      staticClass: "tab-panel",
      attrs: {
        "label": "".concat(_vm.counts[tab.name], " ").concat(tab.label),
        "name": tab.name
      }
    }, [_c('p', [_c('strong', [_vm._v(_vm._s(tab.desc) + " ")])]), _c('FilterTable', {
      key: _vm.tableKey,
      attrs: {
        "namespace": "vendor",
        "trigger": _vm.trigger,
        "export-csv": _vm.vendorExportCsv,
        "fetch-items": _vm.vendorsGetVendors,
        "fetch-items-params": _vm.params(tab.name),
        "columns": _vm.generateCols,
        "search-filters": ['isActiveInTcf', 'hasDeclaredLegitimateInterests', 'isInAdstxt', 'vendorSurveillanceStatus', 'search', 'show-category-exemption', 'isExempted'],
        "data-tests": "vendors-cmp-scenarios-table"
      },
      on: {
        "on-items-fetched": function onItemsFetched(res) {
          return _vm.onVendorsFetched(res, tab.name);
        }
      }
    })], 1);
  }), 1)], 1)], 1), _c('SetupVendorModal', {
    ref: "vendorRulesModal",
    on: {
      "on-form-submit-success": _vm.handleModalFormSubmit
    }
  })], 1) : _c('NoScope')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }